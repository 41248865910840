const config = {
  title: 'Arsenal',
  // city: 'Barcelona',
  city: 'Madrid',
}

switch (config.city) {
  case 'Madrid':
    config.apiURL = '//madridmanager.arsenalbarcelona.com/api/public/'
    config.title = 'Arsenal | Madrid'
    break
  case 'local':
    config.apiURL = 'http://localhost:8080/'
    break
  default:
    config.apiURL = '//manager.arsenalbarcelona.com/api/public/'
    break
}

export default config
