import React, { useState, useEffect } from 'react'
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import config from '../config'


const List = () => {
  const { name: modelName, id } = useParams()
  let numberOfItems = (modelName === 'ScheduledActivity') ? 15000 : 100
  if (modelName === 'Literal') {
    numberOfItems = 1000
  }
  const history = useHistory()
  const registeredSheduledActivityPage = (modelName === 'RegisterdScheduledActivity')
  const [data, setData] = useState(false)
  const [visibleData, setVisibleData] = useState(false)
  const [selectorDateFilter, setSelectorDateFilter] = useState("-1")
  const [selectorHourFromFilter, setSelectorHourFromFilter] = useState("-1")
  const [selectorRoomFilter, setSelectorRoomFilter] = useState("-1")
  const [selectorActivityFilter, setSelectorActivityFilter] = useState("-1")
  const [filtersValues, setFiltersValues] = useState({
    date: selectorDateFilter,
    hourFrom: selectorHourFromFilter,
    room: selectorRoomFilter,
    activity: selectorActivityFilter,
  })
  const [title, setTitle] = useState(modelName)
  const [page, setPage] = useState(0)
  const [duplicatedId, setDuplicatedId] = useState(false)
  const [hasMorePages, setHasMorePages] = useState(false)
  const [timeFilterSelector, setTimeFilterSelector] = useState('current')
  const [timeFilter, setTimeFilter] = useState('')
  const [prevModelName, setPrevModelName] = useState()

  useEffect(() => {
    if (modelName !== prevModelName) {
      setPage(0)
      setTimeFilter('')
      setTimeFilterSelector('current')
    }
    setPrevModelName(modelName)
  }, [modelName])
  
  useEffect(() => {
    setData(false)

    fetch(`${config.apiURL}models/list/${modelName}/${numberOfItems}/${page}${timeFilter}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setTitle(`List ${response.body.appConfig.title}`)
          setData(response.body)
          setHasMorePages(response.body.items.length === numberOfItems)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          }
        }
      })
  }, [prevModelName, duplicatedId, timeFilter, page])

  useEffect(() => {
    resetFilters()
    if (data) setVisibleData({...data})
  }, [data])

  const resetFilters = () => {
    setSelectorDateFilter("-1")
    setSelectorHourFromFilter("-1")
    setSelectorRoomFilter("-1")
    setSelectorActivityFilter("-1")
    setVisibleData({...data})
  }

  const updateVisibleData = (filter, value) => {
    switch (filter) {
      case 'date':
        setSelectorDateFilter(value)
        break
      case 'hourFrom':
        setSelectorHourFromFilter(value)
        break
      case 'room':
        setSelectorRoomFilter(value)
        break
      case 'activity':
        setSelectorActivityFilter(value)
        break
    }
  }

  useEffect(() => {
    const tempData = {...data};
    if (selectorDateFilter !== "-1") {
      tempData.items = tempData.items.filter((item) => item['date'] === selectorDateFilter)
    }
    if (selectorHourFromFilter !== "-1") {
      tempData.items = tempData.items.filter((item) => item['hourFrom'] === selectorHourFromFilter)
    }
    if (selectorRoomFilter !== "-1") {
      tempData.items = tempData.items.filter((item) => item['room'] === selectorRoomFilter)
    }
    if (selectorActivityFilter !== "-1") {
      tempData.items = tempData.items.filter((item) => item['activity'] === selectorActivityFilter)
    }

    if (tempData.items && tempData.items.length !== 0) {
      setVisibleData({...tempData})
    } else {
      resetFilters()
      setVisibleData({...data})
    }
  }, [selectorDateFilter,selectorHourFromFilter,selectorRoomFilter,selectorActivityFilter])

  const onChangeTimeFilter = (value) => {
    if (value === 'past') {
      setTimeFilter('/past')
    } else {
      setTimeFilter('')
    }
    setTimeFilterSelector(value)
  }

  const duplicate = id => {
    if (window.confirm('Duplicate?')) {
      fetch(`${config.apiURL}models/duplicate/${modelName}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            setDuplicatedId(response.body.id)
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        })
    }
  }

  const remove = id => {
    if (window.confirm('Delete?')) {
      fetch(`${config.apiURL}models/delete/${modelName}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            data.items = data.items.filter(item => item.id !== id)
            setData({ ...data })
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        })
    }
  }

  return (
    <div>
      <Helmet>
        <title>{title} – {config.title}</title>
      </Helmet>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">{title}</h2>
        <Link to={`/admin/edit/${modelName}`} className="cursor-pointer hover:wght-semibold">Add</Link>
      </div>
      {registeredSheduledActivityPage && <div className="pb-4">
        <select
          name="selectorTimeFilter"
          type="text"
          value={timeFilterSelector}
          onChange={e => {onChangeTimeFilter(e.target.value)}}
          className="p-1 rounded"
        >
          <option key={1} value="current">Current registers</option>
          <option key={2} value="past">Past registers</option>
        </select>
      </div>}
      {registeredSheduledActivityPage && <div className="flex pb-4">
        <span>Filter by: </span>
        <select
          name="selectorDateFilter"
          type="text"
          value={selectorDateFilter}
          onChange={e => {updateVisibleData('date', e.target.value)}}
          className={`mx-2 rounded ${selectorDateFilter !== '-1' ? 'bg-purple' : ''}`}
        >
          <option key={-1} value={-1}>Date</option>
          {visibleData && visibleData.items && visibleData.items.filter((value, index, self) => {
            return self.findIndex(v => v.date === value.date) === index;
          }).map(item => <option key={item.id} value={item.date}>{item.date}</option>)}
        </select>
        <select
          name="selectorHourFromFilter"
          type="text"
          value={selectorHourFromFilter}
          onChange={e => {updateVisibleData('hourFrom', e.target.value)}}
          className={`mx-2 rounded ${selectorHourFromFilter !== '-1' ? 'bg-purple' : ''}`}
        >
          <option key={-1} value={-1}>Hour from</option>
          {visibleData && visibleData.items &&
          visibleData.items.filter((value, index, self) => {
            return self.findIndex(v => v.hourFrom === value.hourFrom) === index;
          }).sort((a,b) => {
            return a.hourFrom.localeCompare(b.hourFrom)
          }).map(item => {
            return <option key={item.id} value={item.hourFrom}>{item.hourFrom}</option>
          })}
        </select>
        <select
          name="selectorRoomFilter"
          type="text"
          value={selectorRoomFilter}
          onChange={e => {updateVisibleData('room', e.target.value)}}
          className={`mx-2 rounded ${selectorRoomFilter !== '-1' ? 'bg-purple' : ''}`}
        >
          <option key={-1} value={-1}>Room</option>
          {visibleData && visibleData.items && visibleData.items.filter((value, index, self) => {
            return self.findIndex(v => v.room === value.room) === index;
          }).map(item => <option key={item.id} value={item.room}>{item.room}</option>)}
        </select>
        <select
          name="selectorActivityFilter"
          type="text"
          value={selectorActivityFilter}
          onChange={e => {updateVisibleData('activity', e.target.value)}}
          className={`mx-2 rounded ${selectorActivityFilter !== '-1' ? 'bg-purple' : ''}`}
        >
          <option key={-1} value={-1}>Activity</option>
          {visibleData && visibleData.items && visibleData.items.filter((value, index, self) => {
            return self.findIndex(v => v.activity === value.activity) === index;
          }).map(item => <option key={item.id} value={item.activity}>{item.activity}</option>)}
        </select>
        <span onClick={() => resetFilters()} className="px-2 py-1 bg-white rounded cursor-pointer justify-self-end wght-normal hover:wght-semibold">Reset filters</span>
      </div>}
      {!visibleData && !visibleData.items && <div>Loading...</div>}
      {visibleData && visibleData.items && visibleData.items.length === 0 && <div>Nothing to list</div>}
      {visibleData && visibleData.items && visibleData.items.length > 0 && <table className="w-full text-left table-auto">
        <thead className="w-full bg-white">
          <tr>
            {Object.values(visibleData.config.list).map(label => (
              <th key={label} className="px-4 py-2 wght-semibold">{label}</th>
            ))}
            <th className="px-4 py-2 text-right wght-semibold">Actions</th>
          </tr>
        </thead>
        <tbody>
          {visibleData.items.map((item, index) => <tr key={item.id} className={`${index % 2 ? 'bg-grayLighter' : ''}`}>
            {Object.keys(visibleData.config.list).map(key => (
              <td key={key} className="p-2 text-sm">{typeof item[key] !== 'undefined' && item[key]}</td>
            ))}
            {modelName === 'RegisterdScheduledActivity' ? <td className="p-2 text-sm text-right">
              <Link className="mr-4 cursor-pointer hover:wght-semibold" to={`/admin/list-registered/${item.id}`}>View</Link>
            </td> : <td className="p-2 text-sm text-right">
              <Link className="mr-4 cursor-pointer hover:wght-semibold" to={`/admin/edit/${modelName}/${item.id}`}>Edit</Link>
              <span className="mr-4 cursor-pointer hover:wght-semibold" onClick={() => duplicate(item.id)}>Duplicate</span>
              <span className="cursor-pointer hover:wght-semibold" onClick={() => remove(item.id)}>Delete</span>
            </td>}
          </tr>)}
        </tbody>
      </table>}
      <div className="flex mt-8">
        {page > 0 && <span onClick={() => setPage(page - 1)} className="mr-4 cursor-pointer hover:wght-semibold">Previous page</span>}
      {hasMorePages && <span onClick={() => setPage(page + 1)} className="mr-4 cursor-pointer hover:wght-semibold">Next page</span>}
      </div>
    </div>
  )
}

export default List