import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { Helmet } from 'react-helmet'
import Login from './Login'
import Main from './Main'
import config from '../config'

const App = () => {
  const validateToken = () => {
    const token = Cookies.get('token')
    let tokenValid = false
    if (token) {
      const decoded = jwt_decode(token)
      if (Date.now() < decoded.exp * 1000) {
        tokenValid = true
      }
    }
    return tokenValid
  }

  return (
    <>
      <Helmet>
        <title>{config.title}</title>
      </Helmet>
      <Router basename={process.env.NODE_ENV === 'development' ? '/' : '/app'}>
        <Route
          exact
          path="/"
          render={({ location }) => (validateToken() ? (
            <Redirect to={`/admin/list/`} />
          ) : (
            <Redirect to="/login" />
          ))}
        />
        <Route
          path="/login"
          render={({ location }) => (validateToken() ? (
            <Redirect to={`/admin/list/`} />
          ) : (
            <Login />
          ))}
        />
        <Route
          path="/admin"
          render={({ location }) => (validateToken() ? (
            <Main />
          ) : (
            <Redirect to={{ to: '/', state: { from: location } }} />
          ))}
        />
      </Router>
    </>
  )
}

export default App
