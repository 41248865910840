import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import config from '../../config'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'


const sanitizeValue = v => v
  .split(',')
  .filter(i => i.length > 0 && i !== ',')
  .join(',')

const Select = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: {
    type, label, model, multiple, options: staticOptions, where
  },
  validations,
  languages,
}) => {
  const history = useHistory()
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [options, setOptions] = useState([{ id: -1, title: label }])
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable, multiple ? '' : -1),
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState(multiple ? '' : -1)

  const shouldAddMultipleValue = (val, v) => {
    let newV = ''
    if (!val.includes(v)) {
      newV = `${val},${v}`
    } else {
      newV = `${v}`
    }
    return sanitizeValue(newV)
  }

  const onChangeText = e => {
    if (translatable) {
      value[language] = multiple
        ? shouldAddMultipleValue(value[language], e.target.value)
        : e.target.value
      setValue({ ...value })
    } else {
      setValue(
        multiple
          ? shouldAddMultipleValue(value, e.target.value)
          : e.target.value,
      )
    }
  }

  const removeItem = id => {
    if (translatable) {
      value[language] = sanitizeValue(value[language].replace(id, ''))
      setValue({ ...value })
    } else {
      setValue(sanitizeValue(value.replace(id, '')))
    }
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || -1}` : `${value}`)
  }, [value, language, translatable])

  useEffect(() => {
    if (type === 'static') {
      setOptions(o => [
        ...o,
        ...Object.keys(staticOptions).map(key => ({
          id: key,
          title: staticOptions[key],
        })),
      ])
    } else {
      const urlParams = new URLSearchParams(Object.entries(typeof where !== 'undefined' ? where : {}));
      fetch(`${config.apiURL}models/find/${model}?${urlParams}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      })
        .then(response => response
          .json()
          .then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            setOptions(o => [...o, ...response.body])
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        })
    }
  }, [name, type, staticOptions])

  useEffect(() => {
    if (defaultValue(name, storedData, translatable) === value) return
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const multipleOption = id => {
    const option = options.find(item => item.id === id)
    return (
      <div
        key={id}
        className="flex-grow-0 flex-shrink-0 px-1 mb-1 mr-1 text-xs border border-black rounded"
      >
        {option ? option.title : `Not found ${id}`}
        <span className="pl-1 cursor-pointer" onClick={() => removeItem(id)}>
          ✕
        </span>
      </div>
    )
  }
  const select = (
    <div>
      <div className="relative">
        <div className="absolute right-0 py-1 mr-2">&#8595;</div>
        <select
          name={name}
          className={`w-full px-2 py-1 bg-white ${
            showErrors && errors.length > 0 ? 'border border-error' : ''
          } rounded ${
            translatable && Object.keys(languages)[0] === language
              ? 'rounded-tl-none'
              : ''
          } outline-none appearance-none`}
          type="text"
          placeholder={label}
          value={multiple ? -1 : currentValue}
          onChange={onChangeText}
        >
          {options.map(option => (
            <option
              key={option.id}
              disabled={
                option.disabled
                || (multiple && currentValue.includes(option.id))
              }
              value={option.id}
            >
              {option.title}
            </option>
          ))}
        </select>
      </div>
      {multiple && currentValue.length > 0 && options.length > 1 && (
        <div className="flex flex-wrap mt-2">
          {currentValue
            .split(',')
            .map(id => id.length > 0 && multipleOption(id))}
        </div>
      )}
    </div>
  )

  return (
    <div>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {select}
        </LanguageSelector>
      ) : (
        select
      )}
    </div>
  )
}

export default Select
