import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useHistory,
} from 'react-router-dom'
import Cookies from 'js-cookie'
import config from '../config'
import Edit from './Edit'
import List from './List'
import ListRegistered from './ListRegistered'
import Container from './Container'

const Main = () => {

  const { name: modelName, id: urlId } = useParams()
  const [appConfig, setAppConfig] = useState(false)
  useEffect(() => {
    fetch(`${config.apiURL}app/config`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setAppConfig(response.body)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          }
        }
      })
  }, [])

  const deploy = () => {
    if (window.confirm('##Deploy##\n\nYou should only deploy once everything is correct.')) {
      fetch(appConfig.appConfig.deploy.url, {
        method: 'POST',
        body: {},
      })
        .then(response => console.log('Deployed'))
      alert('It might take a while to publish the changes...')
      window.open(appConfig.appConfig.deploy.afterDeployUrl)
    }
  }

  const history = useHistory()
  const logout = () => {
    Cookies.remove('token')
    Cookies.remove('user')
    history.replace('/login')
  }
  let username = 'Anonymous'
  if (Cookies.get('user')) {
    const userData = JSON.parse(Cookies.get('user'))
    if (userData) {
      username = userData.name
    }
  }

  const isMenuItemActive = item => {
    let active = false
    if (typeof item.items !== 'undefined') {
      item.items.forEach(subitem => {
        if (!active && history.location.pathname.includes('/' + subitem.model)) {
          active = true
        }
      })
    }
    if (!active) {
      return history.location.pathname.includes('/' + item.model)
    }
    return active
  }

  return (
    <div className="flex flex-col min-h-screen">
      <nav className="py-12 bg-white hideonprint">
        <Container className="justify-between md:flex">
          {appConfig &&
          <div>
            <ul className="flex">
              {appConfig.appConfig.menu.map(item => (
                <li key={item.model} className="mr-4">
                  <NavLink className="hover:wght-semibold" activeClassName="wght-semibold" to={item.path || `/admin/list/${item.model}`}>
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
            {appConfig.appConfig.menu.map(item => {
              if (isMenuItemActive(item)) {
                return (
                  <ul key={item.model} className="flex mt-4 text-xs">
                    {
                      item.items && item.items.map(subitem => (
                        <li key={subitem.model} className="mr-4">
                          <NavLink className="hover:wght-semibold" activeClassName="wght-semibold" to={subitem.path || `/admin/list/${subitem.model}`}>
                            {subitem.title}
                          </NavLink>
                        </li>
                      ))
                    }
                  </ul>
                )
              }
              return null
            })}
          </div>
          }
          {appConfig && appConfig.appConfig.deploy && <div className="hidden wght-semibold md:block">
            <div className="p-2 text-xs text-center rounded cursor-pointer bg-orange" onClick={deploy}>Deploy {appConfig.appConfig.deploy.title}</div>
          </div>}
        </Container>
      </nav>
      <div className="flex-1 py-12">
        <Container>
          <Switch>
            <Route path="/admin/list/:name/:id?">
              <List />
            </Route>
            <Route path="/admin/edit/:name/:id?">
              <Edit />
            </Route>
            <Route path="/admin/list-registered/:id?">
              <ListRegistered />
            </Route>
          </Switch>
        </Container>
      </div>
      <div className="py-12 bg-white hideonprint">
        <Container>
          <div className="flex justify-between">
            <div className="text-xs">
              👋 Hi <span className="capitalize">{username}</span>. Would you like to <span onClick={logout} className="cursor-pointer wght-bold hover:wght-semibold">log out</span>?
            </div>
            <div className="text-xs">
              {config.title}
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default Main
