import React, { useState, useEffect } from 'react'
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import config from '../config'
import moment from 'moment'

const numberOfItems = 3000

const List = () => {
  const history = useHistory()
  const { id } = useParams()
  const [data, setData] = useState(false)
  const [title, setTitle] = useState('Registered')
  const [page, setPage] = useState(0)
  const [hasMorePages, setHasMorePages] = useState(false)
  const [newEntryName, setNewEntryName] = useState('')
  const [newEntryEmail, setNewEntryEmail] = useState('')
  const [newEntryError, setNewEntryError] = useState()
  const [loading, setLoading] = useState(true)

  const modelName = 'patata'

  useEffect(() => {
    if (loading) {
      setData(false)
  
      fetch(`${config.apiURL}models/list-registered/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            setTitle(`List ${response.body.appConfig.title}`)
            setData(response.body)
            setHasMorePages(response.body.items.length === numberOfItems)
            setLoading(false)
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        })
    }
  }, [loading])

  const addItem = () => {
    setNewEntryError()

    const body = {
      activity: data.info.activityId,
      date: data.info.date,
      hourTo: data.info.hourTo,
      hourFrom: data.info.hourFrom,
      room: data.info.roomId,
      name: newEntryName,
      email: newEntryEmail,
      userId: '-1',
    }

    fetch(`${config.apiURL}public/register-for-activity`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          if (response.body.valid) {
            setNewEntryName('')
            setNewEntryEmail('')
            setLoading(true)
          } else if (response.body.error) {
            setNewEntryError(response.body.error)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          }
        }
      })
  }

  const remove = id => {
    if (window.confirm('Delete?')) {
      fetch(`${config.apiURL}models/delete/RegisterdScheduledActivityPerson/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            data.items = data.items.filter(item => item.id !== id)
            setData({ ...data })
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        })
    }
  }

  const print = () => {
    window.print();
  }

  return (
    <div>
      <Helmet>
        <title>{title} – {config.title}</title>
      </Helmet>
      <div className="flex items-center justify-between mb-4">
        <div>
        <h2 className="text-xl wght-semibold">{data && `${data.info.activity}`}</h2>
        <div>{data && `Fecha: ${moment(data.info.date).format('DD/MM/YYYY')} ${data.info.hourFrom} - ${data.info.hourTo}`}</div>
        <div>{data && `Sala: ${data.info.room}`}</div>
        <div>{data && `Inscritos: ${data.items.length}`}</div>
        </div>
        <Link to={`/admin/list/RegisterdScheduledActivity`} className="cursor-pointer hover:wght-semibold hideonprint">Go back</Link>
      </div>
      <div className="flex justify-end w-full pb-4 hideonprint">
        <span onClick={() => print()} className="px-2 py-1 bg-white rounded cursor-pointer justify-self-end wght-normal hover:wght-semibold">Print</span>
      </div>
      {!data && <div>Loading...</div>}
      {data && data.items.length === 0 && <div>Nothing to list</div>}
      {data && data.items.length > 0 && <table className="w-full text-left table-auto">
        <thead className="w-full bg-white">
          <tr>
            <th className="px-4 py-2 wght-semibold">Name</th>
            <th className="px-4 py-2 wght-semibold">Email</th>
            <th className="px-4 py-2 wght-semibold"></th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((item, index) => <tr key={item.id} className={`${index % 2 ? 'bg-grayLighter' : ''}`}>
            <td key={'name'} className="px-4 py-2">{item.name}</td>
            <td key={'email'} className="px-4 py-2">{item.email}</td>
            <td className="px-4 py-2 text-right">
              <span className="cursor-pointer hideonprint hover:wght-semibold text-error" onClick={() => remove(item.id)}>Delete</span>
            </td>
          </tr>)}
        </tbody>
      </table>}
      <table className="w-full text-left table-auto hideonprint">
        <tbody>
          <tr className={`border-t-24 border-grayLight `}>
          <td className="px-2">Add new entry:</td>
          </tr>
          <tr className={`bg-white`}>
            <td className="px-2">
              <input
                name="name"
                className={`w-full rounded px-2 py-1 bg-white outline-none placeholder-gray`}
                type="text"
                placeholder="Name"
                value={newEntryName}
                onChange={(e) => setNewEntryName(e.target.value)}
                />
            </td>
            <td className="px-2">
              <input
                name="name"
                className={`w-full rounded px-2 py-1 bg-white outline-none placeholder-gray`}
                type="text"
                placeholder="Email"
                value={newEntryEmail}
                onChange={(e) => setNewEntryEmail(e.target.value)}
              />
            </td>
            <td className="px-4 py-2 text-right">
              <span className="cursor-pointer hideonprint hover:wght-semibold " onClick={() => addItem()}>Add</span>
            </td>
          </tr>
        </tbody>
      </table>
      {newEntryError && <span className="block mt-1 ml-2 text-error">{newEntryError}</span>}
      {hasMorePages
      && <div className="flex mt-8">
        {page > 0 && <span onClick={() => setPage(page - 1)} className="mr-4 cursor-pointer hover:wght-semibold">Previous page</span>}
        <span onClick={() => setPage(page + 1)} className="mr-4 cursor-pointer hover:wght-semibold">Next page</span>
      </div>}
    </div>
  )
}

export default List