import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import config from '../config'
import Form from './Form/Form'
import flatten from './Lib/flatten'

const modelsWithoutList = []

const Edit = () => {
  const history = useHistory()
  const { name: modelName, id: urlId } = useParams()
  const [id, setId] = useState(urlId)
  const [data, setData] = useState(false)
  const [name, setName] = useState(modelName)
  const [title, setTitle] = useState(name)
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)

  const updateStored = (key, value, errs = []) => {
    if (typeof data.stored.modelName === 'undefined') { data.stored.modelName = name }
    data.stored[key] = value
    errors[key] = errs
    setData({ ...data })
    setErrors({ ...errors })
  }

  useEffect(() => {
    setData(false)
    setName(modelName)

    fetch(`${config.apiURL}models/edit/${modelName}${id ? `/${id}` : ''}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (Array.isArray(response.body.stored)) {
          response.body.stored = {}
        }
        return response
      })
      .then(response => {
        if (response.status === 200) {
          setTitle(`${id ? 'Edit' : 'Create'} ${response.body.appConfig.title}`)
          setData(response.body)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('<to></to>ken')
            history.push('/login')
          }
        }
      })
  }, [modelName, id])

  const save = (type = 'default', callback = false) => {
    const errs = flatten(errors)
    if (Object.keys(flatten(errs)).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setSaving(true)
      fetch(`${config.apiURL}models/edit/${modelName}`, {
        method: 'PUT',
        body: JSON.stringify(data.stored),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      })
        .then(response => response
          .json()
          .then(data => ({ status: response.status, body: data })))
        .then(response => {
          setSaving(false)
          if (callback) callback(response)
          if (response.status === 200) {
            if (type === 'default') {
              if (typeof id === 'undefined') {
                setId(response.body.id)
                history.push(`/admin/edit/${modelName}/${response.body.id}`)
              }
              setSaved(true)
              setTimeout(() => setSaved(false), 3000)
              return
            } else if (type === 'save-and-another') {
              setId(null)
              history.push(`/admin/edit/${modelName}`)
              return
            } else if (type === 'save-and-close') {
              if (modelsWithoutList.includes(modelName)) {
                history.push(`/admin/edit/${modelName}/1`)
              } else {
                history.push(`/admin/list/${modelName}`)
              }
            }
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        })
        .catch(() => setSaving(false))
    }
  }

  return (
    <div>
      <Helmet>
        <title>
          {title} – {config.title}
        </title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="mb-8 text-xl wght-semibold">{title}</h2>
        <Link
          to={`/admin/list/${modelName}`}
          className="cursor-pointer hover:wght-semibold"
        >
          Go back
        </Link>
      </div>
      {!data && <div className="mb-8">Loading...</div>}
      {data && modelName === name && (
        <>
          <Form
            onChange={updateStored}
            data={data}
            name={name}
            showErrors={showErrors}
            mode={id ? 'edit' : 'create'}
          />
          <div className="sticky bottom-0 z-40 flex justify-between px-4 py-2 mb-8 mr-4 text-xs bg-black">
            <div>
              {saving ? (
                <span className="text-white wght-bold">Saving...</span>
              ) : (
                <span
                  onClick={() => save()}
                  className="px-2 py-1 bg-white rounded cursor-pointer wght-normal hover:wght-semibold"
                >
                  Save &#8599;{' '}
                </span>
              )}
              {saved && <span className="ml-4 text-white">Saved successfully!</span>}
            </div>
            <div>
              {saving ? null : (
                <span
                  onClick={() => save('save-and-another')}
                  className="px-2 py-1 bg-white rounded cursor-pointer wght-normal hover:wght-semibold"
                >
                  Save &amp; create &#8599;{' '}
                </span>
              )}
            </div>
            <div>
              {saving ? null : (
                <span
                  onClick={() => save('save-and-close')}
                  className="px-2 py-1 bg-white rounded cursor-pointer wght-normal hover:wght-semibold"
                >
                  Save &amp; close &#8599;
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Edit
