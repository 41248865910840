import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import config from '../../config'
import LanguageSelector from './LanguageSelector'
import MediaViewer from './MediaViewer'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const Image = ({
  storedData,
  onChange,
  name,
  translatable,
  options: { label },
  validations,
  languages,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [currentValue, setCurrentValue] = useState('')
  const [dragging, setDragging] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [showMediaView, setShowMediaViewer] = useState(false)

  const updateValue = v => {
    if (translatable) {
      value[language] = v
      setValue({ ...value })
    } else {
      setValue(v)
    }
    if (showMediaView) setShowMediaViewer(false)
  }

  const remove = e => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm('Confirm?')) {
      updateValue('')
    }
  }

  const onDrop = e => {
    e.stopPropagation()
    e.preventDefault()
    const supportedFilesTypes = ['image/jpeg', 'image/png', 'image/gif']
    const { type } = e.dataTransfer.files[0]
    if (supportedFilesTypes.indexOf(type) > -1) {
      setUploading(true)
      const payload = new FormData()
      payload.append('file', e.dataTransfer.files[0])
      fetch(`${config.apiURL}media/upload`, {
        body: payload,
        method: 'post',
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      })
        .then(response => {
          setUploading(false)
          if (response.status === 200) {
            return response.json()
          }
          return {}
        })
        .then(response => {
          if (response.id) {
            updateValue(response.id)
          }
        })
    }
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [value, language, translatable])

  useEffect(() => {
    onChange(name, value, Errors(value, validations, translatable, languages))
  }, [value])

  const url = currentValue
    ? `${config.apiURL}media/view/${currentValue}/80/80/75/preview`
    : `${process.env.PUBLIC_URL}/assets/images/no-image-small.jpg`

  const input = (
    <div>
      <label>{label}</label>
      <div
        className="relative flex items-stretch items-center bg-white rounded cursor-pointer"
        onClick={() => setShowMediaViewer(true)}
      >
        <div
          onClick={remove}
          className="absolute top-0 right-0 mt-1 mr-2 text-gray hover:text-black"
        >
          ✕
        </div>
        <div className="w-16 h-16">
          <img className="rounded-l" alt="Preview" src={url} />
        </div>
        <div
          onDragEnter={() => setDragging(true)}
          onDragLeave={() => setDragging(false)}
          onDragOver={e => e.preventDefault() && e.stopPropagation()}
          onDrop={e => onDrop(e) && setDragging(false)}
          className={`${
            dragging ? 'border-gray' : ''
          } border border-white rounded-tr rounded-br flex-1 text-xs text-gray flex justify-center items-center`}
        >
          <span className="text-center pointer-events-none">
            {uploading ? (
              'Uploading...'
            ) : (
              <span>
                Drag to upload
                <span className="block text-xxs">Click to select</span>
              </span>
            )}
          </span>
        </div>
      </div>
      {showMediaView && (
        <MediaViewer
          type="image"
          value={currentValue}
          onChange={updateValue}
          onClose={() => setShowMediaViewer(false)}
        />
      )}
    </div>
  )

  return translatable ? (
    <LanguageSelector
      language={language}
      languages={languages}
      onChangeLanguage={v => setLanguage(v)}
    >
      {input}
    </LanguageSelector>
  ) : (
    input
  )
}

export default Image
